<template>
  <div class="patient-scheduling">
    <VToolbar dense>
      <VBtn text class="px-0" style="min-width: 36px">
        <VIcon>mdi-menu</VIcon>
      </VBtn>
      <VDivider vertical />
      <VToolbarTitle class="justify-start">{{ title[$route.params.for] }}</VToolbarTitle>
      <VSpacer />
      <VDivider vertical />
      <VBtn depressed color="primary">加入</VBtn>
    </VToolbar>
    <VContainer fluid>
      <VDataTable :headers="headers" :items="items" item-key="selected" show-select />
    </VContainer>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'PatientScheduling',
  data: () => ({
    title: {
      first: '第一血液透析室',
      second: '第二血液透析室',
      hospitalized: '住院病房',
    },
    headers: [
      {
        text: '',
        sortable: false,
        value: 'selected',
      },
      { text: '報到', value: 'a' },
      { text: '病患姓名', value: 'b' },
      { text: '病歷號碼', value: 'c' },
      { text: '病歷來源', value: 'd' },
      { text: '透析床號', value: 'e' },
      { text: '住院床號', value: 'f' },
      { text: '當班醫師', value: 'g' },
      { text: '主治醫師', value: 'h' },
      { text: '當班護理師', value: 'i' },
      { text: '主要護理師', value: 'j' },
      { text: '透析機號碼', value: 'k' },
    ],
    items: [
      {
        a: 'a1',
        b: 'b1',
        c: 'c1',
        d: 'd1',
        e: 'e1',
        f: 'f1',
        g: 'g1',
        h: 'h1',
        i: 'i1',
        j: 'j1',
        k: 'k1',
      },
      {
        a: 'a2',
        b: 'b2',
        c: 'c2',
        d: 'd2',
        e: 'e2',
        f: 'f2',
        g: 'g2',
        h: 'h2',
        i: 'i2',
        j: 'j2',
        k: 'k2',
      },
    ],
  }),
});
</script>

<style lang="scss">
.patient-scheduling {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 800px;
        width: 800px;
        padding: 8px;
        .row {
          margin: -4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }

        .col {
          padding: 4px;
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
          }

          .col {
            padding-top: 12px;
          }

          th,
          td {
            min-width: 80px;
            text-align: center;
            padding: 0px;
            .v-input {
              border-radius: 0px;
              &:not(.v-input--is-focused)
                > .v-input__control
                > .v-input__slot:not(:hover):not(:active)
                > fieldset {
                color: transparent;
              }
            }
          }

          $border-colors: (
            'theme--light': rgba(0, 0, 0, 0.12),
            'theme--dark': rgba(255, 255, 255, 0.12),
          );
          @each $theme, $border-color in $border-colors {
            .#{$theme}.v-data-table > .v-data-table__wrapper > table > {
              thead,
              tbody {
                > tr > {
                  td,
                  th {
                    &:not(:last-child) {
                      border-right: thin solid $border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
